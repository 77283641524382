<template>
  <v-form
    :lazy-validation="true"
    @submit.prevent="handleSubmit"
    v-model="valid"
    ref="form"
  >
    <v-card :loading="status.loading || downloading" class="text-center">
      <v-card-title class="headline">
        {{ $t("report.export") }}
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="10"
              md="10"
              justify="center"
              align="center"
              class="pl-16 ml-2"
            >
              <v-date-picker v-model="rangesArr" range></v-date-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-text>
        <v-autocomplete
          autocomplete="off"
          :disabled="status.loading"
          :loading="status.loading"
          :label="$t('report.fields.type')"
          v-model="type"
          :items="reportTypes"
          item-text="name"
          item-value="value"
          prepend-icon="mdi-form-select"
          class="pa-0 ma-0"
          required
        ></v-autocomplete>
      </v-card-text>

      <v-card-text>
        <v-autocomplete
          autocomplete="off"
          :disabled="status.loading"
          :loading="status.loading"
          :label="$t('tag.name')"
          v-model="tag"
          :items="tags"
          item-text="name"
          item-value="deveui"
          prepend-icon="mdi-lock-open"
          :rules="autocompleteRules"
          class="pa-0 ma-0"
        ></v-autocomplete>
      </v-card-text>

      <v-card-text>
        <v-autocomplete
          autocomplete="off"
          :disabled="status.loading || tag === null || keyStatus.loading"
          :loading="status.loading || keyStatus.loading"
          :label="$t('alarm.fields.tagDataKey')"
          v-model="tagDataKey"
          :items="keys"
          item-text="label"
          item-value="key"
          prepend-icon="mdi-key"
          :rules="autocompleteRules"
          class="pa-0 ma-0"
        ></v-autocomplete>
      </v-card-text>

      <v-btn color="accent" text @click="newInclude()">
        {{ $t("common.add") }}
      </v-btn>

      <v-simple-table v-if="this.includedTags.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{ $t("alarm.fields.tag") }}</th>
              <th class="text-left">{{ $t("module.meta.key") }}</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(m, i) in includedTags" :key="i">
              <td class="text-left">{{ m.deveui }}</td>
              <td class="text-left">
                {{ m.key }}
              </td>
              <td>
                <v-btn color="error" fab x-small @click="removeListItem(i)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleSubmit">
          {{ $t("common.get") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ReportExport",

  data() {
    return {
      type: 0,
      valid: false,
      tag: null,
      tagDataKey: null,
      includedTags: [],
      rangesArr: [],
      ranges: {
        start: new Date(),
        end: new Date(),
      },
      downloading: false,
      autocompleteRules: [
        (v) => !!v || this.$t("alarm.validation.nameRequired"),
      ],
      reportTypes: [
        { name: this.$t("report.types.regular"), value: 0 },
        { name: this.$t("report.types.trend"), value: 1 },
      ],
    };
  },

  computed: {
    ...mapState("tag", { status: "status", tags: "tags" }),
    ...mapState("users", ["currentUser"]),
    ...mapState("tagData", { keyStatus: "status", keys: "keys" }),
  },

  methods: {
    ...mapActions("tag", ["getTags", "getKeysForTag"]),
    ...mapActions("reports", ["multiExport"]),

    removeListItem(i) {
      this.includedTags.splice(i, 1);
    },

    async newInclude() {
      if (!this.tag || !this.tagDataKey) return;
      this.includedTags.push({
        key: this.tagDataKey,
        tag: this.tag.name,
        deveui: this.tag,
      });

      this.tag = null;
      this.tagDataKey = null;
    },

    async handleSubmit() {
      if (!this.includedTags.length > 0) {
        this.valid = false;
        return;
      }

      var payloadTags = [];
      var payloadKeys = [];
      for (var i = 0; i < this.includedTags.length; i++) {
        payloadTags.push(this.includedTags[i].deveui);
        payloadKeys.push(this.includedTags[i].key);
      }

      this.downloading = true;
      // Start the export
      const ranges = this.ranges;
      var payload = {
        type: this.type,
        tagIds: payloadTags,
        keys: payloadKeys,
        from: ranges.start,
        to: ranges.end,
      };

      await this.multiExport({ payload: payload });

      this.$refs.form.reset();
      this.includedTags = [];
      this.$emit("download-complete");

      this.downloading = false;
    },
  },

  watch: {
    tag(val) {
      if (val != null && val != undefined) this.getKeysForTag({ tagId: val });
    },

    rangesArr(val) {
      if (val.length >= 2) {
        this.ranges.start = new Date(val[0]).toISOString();

        var endDate = new Date(new Date(val[1]).setHours(23, 59, 59));
        this.ranges.end = new Date(
          endDate.getTime() - endDate.getTimezoneOffset() * 60000
        ).toISOString();
      }
    },
  },

  async created() {
    await this.getTags();
  },
};
</script>

<style></style>
